<template>
  <div class="bgcolor">
    <ul>
      <li><a href="https://suncano-brdo.com/"><div class="navImg"><img src="../assets/logo.png" class="imgForNav"></div></a></li>
        <nav>
      <router-link to="/">Tabela</router-link> | 
      <router-link to="/apar">Svi stanovi</router-link> | 
      <router-link to="/admin">Admin</router-link> |
    </nav>
    </ul>

    <h1>Kriterijumi za odabir stana:</h1>
  <div class="odabirtabel">
    <table>
  <tr>
    <th>Površina:</th>
    <td><input type="checkbox" v-model="selectedPovrsina" :value="0"> 50-60 m2</td>
    <td><input type="checkbox" v-model="selectedPovrsina" :value="1"> 60-70 m2</td>
    <td><input type="checkbox" v-model="selectedPovrsina" :value="2"> >70 m2</td>
  </tr>
  <tr>
    <th>Spavaća soba:</th>
    <td><input type="checkbox" v-model="selectedSpavaca" :value="0"> Manja</td>
    <td><input type="checkbox" v-model="selectedSpavaca" :value="1"> Veća</td>  
    <td></td>
  </tr>
  <tr>
    <th>Ostava:</th>
    <td><input type="checkbox" v-model="selectedOstava" :value="true"> Da</td>
    <td><input type="checkbox" v-model="selectedOstava" :value="false"> Ne</td>
    <td></td>
  </tr>
  <tr>
    <th>Garderoba:</th>
    <td><input type="checkbox" v-model="selectedGarderoba" :value="true"> Da</td>
    <td><input type="checkbox" v-model="selectedGarderoba" :value="false"> Ne</td>
    <td></td>
  </tr>
  <tr>
    <th>Balkon:</th>
    <td><input type="checkbox" v-model="selectedBalkon" :value="0"> Manji</td>
    <td><input type="checkbox" v-model="selectedBalkon" :value="1"> Srednji</td>
    <td><input type="checkbox" v-model="selectedBalkon" :value="2"> Veći</td>
  </tr>
  <tr>
    <th>Spratnost:</th>
    <td><input type="checkbox" v-model="selectedSpratnost" :value="0"> Prizemlje</td>
    <td><input type="checkbox" v-model="selectedSpratnost" :value="1"> Zadnji sprat</td>
    <td><input type="checkbox" v-model="selectedSpratnost" :value="2"> Između</td>
  </tr>
  <tr>
    <th>Svetlost:</th>
    <td><input type="checkbox" v-model="selectedSvetlost" :value="0"> Mala</td>
    <td><input type="checkbox" v-model="selectedSvetlost" :value="1"> Delimična</td>
    <td><input type="checkbox" v-model="selectedSvetlost" :value="2"> Velika</td>
  </tr>
  <tr>
    <th>Distanca:</th>
    <td><input type="checkbox" v-model="selectedDistanca" :value="0"> Blizu</td>
    <td><input type="checkbox" v-model="selectedDistanca" :value="1"> Relativno</td>
    <td><input type="checkbox" v-model="selectedDistanca" :value="2"> Udaljeno</td>
  </tr>
  <tr>
    <th>Prometnost:</th>
    <td><input type="checkbox" v-model="selectedPrometnost" :value="0"> Mirno</td>
    <td><input type="checkbox" v-model="selectedPrometnost" :value="1"> Relativno</td>
    <td><input type="checkbox" v-model="selectedPrometnost" :value="2"> Prometno</td>
  </tr>
  <tr>
    <th>Pogled:</th>
    <td><input type="checkbox" v-model="selectedPogled" :value="0"> Slobodan</td>
    <td><input type="checkbox" v-model="selectedPogled" :value="1"> Relativan</td>
    <td><input type="checkbox" v-model="selectedPogled" :value="2"> Ograničen</td>
  </tr>
</table>

  </div>
  <!-- <p>{{this.selectedPovrsina}}</p>
  <p>{{this.selectedSpavaca}}</p>
  <p>{{this.selectedOstava}}</p>
  <p>{{this.selectedGarderoba}}</p>
  <p>{{this.selectedSpratnost}}</p>
  <p>{{this.selectedSvetlost}}</p>
  <p>{{this.selectedDistanca}}</p>
  <p>{{this.selectedPrometnost}}</p>
  <p>{{this.selectedPogled}}</p> -->
  <br>
    <button  @click="findBestApartments" class="buttonfinde">Pronđi najbolji stan</button>
    <br>
   <!-- <hr class="new1"> -->
   <br>
    <hr v-if="isSearchActive" class="new1">
    <h2 v-if="isSearchActive">Najbolji stan:</h2>
    <div v-for="apartment in bestApartments" :key="apartment.id">
      <!-- <p>{{ apartment }}</p> -->
    </div>
     <div class="card-container">
        <div class="card" v-for="(apartment, index) in bestApartments" :key="index">
          <a v-bind:href="apartment.posturl">
            <div class="hover">
              <div class="sold" v-if="apartment.condition==2 || apartment.condition==3"></div>
              <div class="reserveed" v-if="apartment.condition==3"></div>
              <div class="sold-label" v-if="apartment.condition==2">Prodano</div>
              <div class="reserved-label" v-if="apartment.condition==3">Rezervisano</div>
              <img :src="apartment.url" alt="Image" class="imgs">
              <div class="textcolor">
                <div class="alignInCard">
                  <h3>Stan: <span class="maintextcolor">{{ apartment.floor }}{{ apartment.lamela }}{{ apartment.numberofaprtman }}</span></h3>
                  <!-- <h3>Sprat: <span style="color:#dcf3c6">{{ item.floor }}</span></h3>
                  <h3>Broj: <span style="color:#dcf3c6">{{ item.numberofaprtman }}</span></h3> -->
                  <h3>Tip: <span style="color:#dcf3c6">{{ apartment.tip }}</span></h3>
                </div>
                <br>
                <div>
                  <div class="alignInText">
  <div class="spaceindiv">
    <div class="icon-text">
      <img src="../assets/sqm.png" width="30px" height="30px">
      <p>{{ apartment.sqm }}m<sup>2</sup></p>
    </div>
    <div class="icon-text">
      <img src="../assets/3dcube.png" width="30px" height="30px">
      <p>{{ apartment.lamela }}</p>
    </div>
    <div class="icon-text">
      <img src="../assets/floor1.png" width="30px" height="30px">
      <p>{{ apartment.floor }}</p>
    </div>
  </div>  
</div>
<br>
<hr class="new1">
<br>
  <div class="info-data">
  <p style="text-align:center;">- Površina: <span style="color:#dcf3c6">{{showPovrsina(apartment.filter_povrsina)}}</span></p>
  <p style="text-align:center;">- Spavaća soba: <span style="color:#dcf3c6">{{showSpavaca(apartment.filter_spavaca)}}</span></p>
  <p style="text-align:center;">- Ostava: <span style="color:#dcf3c6">{{showOstava(apartment.filter_ostava)}}</span></p>
  <p style="text-align:center;">- Garderober: <span style="color:#dcf3c6">{{showGarderoba(apartment.filter_garderoba)}}</span></p>
  <p style="text-align:center;">- Balkon: <span style="color:#dcf3c6">{{showBalkon(apartment.filter_balkon)}}</span></p>
  <p style="text-align:center;">- Spratnost: <span style="color:#dcf3c6">{{showSpratnost(apartment.filter_sprat)}}</span></p>
  <p style="text-align:center;">- Svetlost: <span style="color:#dcf3c6">{{showSvetlost(apartment.filter_svetlost)}}</span></p>
  <p style="text-align:center;">- Distanca: <span style="color:#dcf3c6">{{showDistanca(apartment.filter_distanca)}}</span></p>
  <p style="text-align:center;">- Prometnost: <span style="color:#dcf3c6">{{showPrometnost(apartment.filter_buka)}}</span></p>
  <p style="text-align:center;">- Pogled: <span style="color:#dcf3c6">{{showPogled(apartment.filter_pogled)}}</span></p>
  </div>
                  <!-- <div class="spaceindiv"><img src="../assets/sobnost.png" width="30px" height="30px"> <p>{{ item.room }}</p></div> -->
                </div>
                <br>
              </div>
            </div>
          </a>
        </div>
      </div> 
 
        <br>
      <router-link to="/apar" v-if="isSearchActive"><button v-if="isSearchActive" class="showmoreapart">Prikaži sve stanove</button></router-link>
      <br><br>
      <p  v-if="name[0].show" style="text-align: center; font-size:13px">Izrada sistema i web programa: <span><a href="https://linktr.ee/senadmustafi">Senad Mustafi</a></span></p>
     <br>
  </div>  
</template>

<script>
import { db, getDocs, collection } from '@/firebase';
import store from "@/store";

export default {
  name: 'TabelPage',
  data() {
    return {
      selectedPovrsina: [],
      selectedSpavaca: [],
      selectedOstava: [],
      selectedGarderoba: [],
      selectedBalkon: [],
      selectedSpratnost: [],
      selectedSvetlost: [],
      selectedDistanca: [],
      selectedPrometnost: [],
      selectedPogled: [],
      buttonIsActive: false,
      showname: false,
      storedata: store.currentUser,
      isSearchActive: false,
      apartments: [],
      bestApartments: [],
      name: []
    };
  },
  methods: {
    async loadName() {
      const querySnapshot = await getDocs(collection(db, "name"));
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id; 
        this.name.push(data);
      });
    },
    async findBestApartments() {
      const querySnapshot = await getDocs(collection(db, "goldenhill"));
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id; 
        this.apartments.push(data);
      });
      const userPreferences = {
        filter_povrsina: this.selectedPovrsina,
        filter_spavaca: this.selectedSpavaca,
        filter_ostava: this.selectedOstava,
        filter_garderoba: this.selectedGarderoba,
        filter_balkon: this.selectedBalkon,
        filter_sprat: this.selectedSpratnost,
        filter_svetlost: this.selectedSvetlost,
        filter_distanca: this.selectedDistanca,
        filter_buka: this.selectedPrometnost,
        filter_pogled: this.selectedPogled
      };
      const apartmentsWithScores = this.apartments.map(apartment => {
        let score = 0;
        for (let key in userPreferences) {
          if (userPreferences[key].length) {
            if (userPreferences[key].includes(apartment[key])) {
              score += 1;
            }
          } else if (userPreferences[key] !== null && apartment[key] === userPreferences[key]) {
            score += 1;
          }
        }
        return { ...apartment, score };
      });
      apartmentsWithScores.sort((a, b) => b.score - a.score);
      this.isSearchActive = true;
      this.bestApartments = apartmentsWithScores.slice(0, 9);
    },
    showPovrsina(povrsina) {
      if (povrsina == 0) {
        return "50-60 m2";
      } else if (povrsina == 1) {
        return "60-70 m2";
      } else if (povrsina == 2) {
        return ">70 m2";
      }
    },
    showSpavaca(spavaca) {
      if (spavaca == 0) {
        return "Manja";
      } else if (spavaca == 1) {
        return "Veća";
      }
    },
    showOstava(ostava) {
      if (ostava == true) {
        return "Da";
      } else if (ostava == false) {
        return "Ne";
      }
    },
    showGarderoba(garderoba) {
      if (garderoba == true) {
        return "Da";
      } else if (garderoba == false) {
        return "Ne";
      }
    },
    showBalkon(balkon) {
      if (balkon == 0) {
        return "Manji";
      } else if (balkon == 1) {
        return "Srednji";
      } else if (balkon == 2) {
        return "Veći";
      }
    },
    showSpratnost(spratnost) {
      if (spratnost == 0) {
        return "Prizremlje";
      } else if (spratnost == 1) {
        return "Zadnji sprat";
      } else if (spratnost == 2) {
        return "Između";
      }
    },
    showSvetlost(svetlost) {
      if (svetlost == 0) {
        return "Mala";
      } else if (svetlost == 1) {
        return "Delimična";
      } else if (svetlost == 2) {
        return "Velika";
      }
    },
    showDistanca(distanca) {
      if (distanca == 0) {
        return "Blizu";
      } else if (distanca == 1) {
        return "Relativno";
      } else if (distanca == 2) {
        return "Daleko";
      }
    },
    showPrometnost(prometnost) {
      if (prometnost == 0) {
        return "Mirno";
      } else if (prometnost == 1) {
        return "Relativno";
      } else if (prometnost == 2) {
        return "Prometno";
      }
    },
    showPogled(pogled) {
      if (pogled == 0) {
        return "Slobodan";
      } else if (pogled == 1) {
        return "Relativan";
      } else if (pogled == 2) {
        return "Ograničen";
      }
    },
  },
  computed: {
    allSelected() {
      return this.selectedPovrsina.length > 0 ||
        this.selectedSpavaca.length > 0 ||
        this.selectedOstava.length > 0 ||
        this.selectedGarderoba.length > 0 ||
        this.selectedBalkon.length > 0 ||
        this.selectedSpratnost.length > 0 ||
        this.selectedSvetlost.length > 0 ||
        this.selectedDistanca.length > 0 ||
        this.selectedPrometnost.length > 0 ||
        this.selectedPogled.length > 0;
    }
  },
  watch: {
    allSelected(newVal) {
      this.buttonIsActive = newVal;
    }
  },
  created() {
    this.loadName();
  }
};

</script>

<style>


.showmoreapart{
  background-color: #008CBA;
  border-radius: 5px;
  border: 0;
  width: 220px;
  color: white;
  font-size: 18px;
  height: 37px;
}

.showmoreapart:hover{
  background-color: #005977;
  border-radius: 5px;
  border: 0;
  width: 220px;
  color: white;
  font-size: 18px;
  height: 37px;
}

hr.new1 {
  border-top: 1px solid blanchedalmond;
  width: 85%;
  
}

.odabirtabel{
  margin: auto;
  width: 70%;
  padding: 10px;
}

.buttonfinde{
  width: 35%;
  height: 50px;
  background-color: rgba(255,255,255,0.8);
  border-color: #04AA6D;
  border-radius: 5px;
  border: none;
  color:  rgb(77, 77, 77);
  font-size: 20px;
}

.buttonfinde:hover{
  background-color: rgba(250, 250, 250, 0.8);
}


th, td {
  padding: 10px;
  border: 1px solid #ccc; /*#ccc;*/
  text-align: left;
}

td{
  font-size: 18px;
}
th{
  font-size: 18px;
}


table {
    width: 100%;
    border-collapse: collapse;
     border-radius: 15px;
    overflow: hidden;
    margin: 20px 0; /* Dodano malo prostora oko tabele */
}

/* Stiliziranje redova */
table tr:nth-child(odd) {
    background-color: #f2f2f2;
}

table tr:nth-child(even) {
    background-color: #ffffff;
}

/* Stiliziranje zaglavlja */
table th {
    padding: 10px;
    text-align: left;
}

/* Stiliziranje ćelija */
table td {

    padding: 10px;
}

/* Stiliziranje checkboxova */
input[type="checkbox"] {
    margin-right: 10px;
}

a:link {
  color: #22568a;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: #22568a;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #01284e;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: #022a52;
  background-color: transparent;
  text-decoration: underline;
}
.navImg{
  position: relative;
  left:100px;
  padding-bottom: 20px;
}
.navText{
  position: relative;
  left:100px;
  margin-top: 15px ;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: linear-gradient(to right, rgba(244, 244, 244, 1) 41%, rgba(244, 244, 244, 0.6) 100%);
}

li {
  float: left;
}

.imgssrrc{
  width: 100%;
  margin: 100%;
  margin: 0%;
}

li a {
  display: block;
  padding: 8px;
}

.alignInCard {
  display: flex; 
  justify-content: space-between; 
  margin-left: 35px;
  margin-right:35px;
  font-size: 18px;
}

.alignInText {
  display: flex;
  justify-content: center; /* Centers the .spaceindiv container horizontally */
  align-items: center;     /* Centers the .spaceindiv container vertically */
  height: 100%;            /* Ensures the container takes the full height of its parent */
}

.spaceindiv {
  display: flex;
  align-items: center;
  gap: 20px; /* Adjust the spacing between icon-text pairs */
}

.icon-text {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust the spacing between icon and text */
}

.icon-text img {
  display: block;
}

.icon-text p {
  margin: 0;
  font-size: 22px; /* Adjust the font size as needed */
}

.icon-text p sup {
  font-size: 22px; /* Adjust the font size for the superscript as needed */
}


.info-data p {
  font-size: 22px;
}




.inlineblok h2 {
  display: inline-block;
  margin-right: 30px; 

}

a:link { 
  text-decoration: none; 
} 
a:visited { 
  text-decoration: none; 
} 
a:hover { 
  text-decoration: none; 
} 
a:active { 
  text-decoration: none; 
}


select {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 12px;
  font-weight: bold;
}
form {
  margin-right: 10px;
}
.dorpDownSearch{
  display: flex;
}
.dropDownContainer{
  display: flex;
  justify-content: center;
}

  .textcolor {
    background-color: #5F574A;
    color: white;
    margin-top: -1%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .soldimg{ 
    opacity: 0.5;
  }
  .bgcolor {

    background-color: #D9D3C7;
    margin-top: -10px;
  }
  
  .hover:hover {
    border-radius: 15px;
    box-shadow: 10px 10px 20px rgba(90, 98, 87, 0.5);
  }
.soldcolor{
  background-color:rgb(255, 255, 255);
  opacity:0.5;
}
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
  }
  .maintextcolor{
     color:#dcf3c6;
  }
  .card {
    position: relative; 
    width: calc(30% - 20px);
    margin: 10px;
    padding: 10px;
    border-radius: 15px;
  }
  .card .imgs {
    width: 100%;
    border-radius: 15px 15px 0 0;
  }

  .card h2 {
    margin-top: 0;
  }
  .card p {
    margin: 5px 0;
  }

 
 
  .sold {
  position: absolute;
  width: calc(100% - 20px); 
  height: calc(100% - 20px); 
  top: 10px;
  left: 10px; 
  background-color: #364932; /* rgb(192, 192, 192); */
  opacity: 0.5;
  color: white;
  border-radius: 15px;
}

  .reserveed {
  position: absolute;
  width: calc(100% - 20px); 
  height: calc(100% - 20px); 
  top: 10px;
  left: 10px; 
  background-color: #745923; /* rgb(192, 192, 192); */
  opacity: 0.5;
  color: white;
  border-radius: 15px;
}
  .sold-label {
    position: absolute;
    width: 120px;
    top: 50px; 
    right: 10px; 
    background-color: #364932; 
    color: white; 
    padding: 5px 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 18px;  
    font-weight: bold;
  }
  .reserved-label{
    position: absolute;
    width: 120px;
    top: 50px;
    right: 10px; 
    background-color: #5F574A; 
    color: white; 
    padding: 5px 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 18px;
    font-weight: bold;

  }


  @media only screen and (max-width: 600px) {
    .card {
      width: 100%;
    }
    .navImg{
  position: relative;
  left:0px;
  padding-bottom: 20px;
}
.odabirtabel{
  margin: 0;
}
  }
</style>
