// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut} from "firebase/auth";
import { getFirestore, collection, getDocs, setDoc, doc,getDocFromCache,addDoc,getDoc, updateDoc } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAaq5KrgEVCT7fQmdjI9sGMhc0-rxnaOFw",
    authDomain: "goldenhill-b16c6.firebaseapp.com",
    projectId: "goldenhill-b16c6",
    storageBucket: "goldenhill-b16c6.appspot.com",
    messagingSenderId: "742350073918",
    appId: "1:742350073918:web:15bc2eae979e9af3b9e16f"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);


export {
    auth,createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut,db,collection,getDocs,setDoc,doc,getDocFromCache, addDoc,getDoc,updateDoc
}